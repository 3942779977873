<template>

  <div>
    <b-card-actions ref="list" title="" action-refresh @refresh="refresh">
      <div class="demo-spacing-0" v-if="selected_rows && selected_rows.length >0">
        <b-alert
            show
            variant="primary"
        >
          <div class="alert-body">
            <feather-icon
                class="mr-25"
                icon="CheckIcon"
            />
            <span class="ml-25">{{ selected_rows_label }}</span>
            <span class="ml-25"> <b-button
                variant="primary"
                class="button-inline-spacing-2"
                @click="show_add_to_company()"
                :disabled="editDisabled"
            >
            <span class="text-nowrap">{{ $t("Add to a company") }}</span>
          </b-button></span>
          </div>
        </b-alert>
      </div>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <export-excel
                :data="rows"
                :columns="excel_columns"
                :file-name="'document_sections'"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
            >
              <feather-icon icon="DownloadIcon"/>
            </export-excel>

            <b-button
                variant="primary"
                class="button-inline-spacing-2"
                @click="add_legalRegulation()"
                :disabled="editDisabled"
            >
              <span class="text-nowrap">{{ $t("Add Legal Regulation") }}</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >

            <b-form-input
                v-model="searchTerm"
                :placeholder="$t('message.SearchPlaceholder')"
                type="text"
                class="d-inline-block"
            />
          </b-col>
        </b-row>

      </div>


      <!-- alert -->
      <!--b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
        </div>
      </b-alert-->

      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">


      </div>

      <!-- table -->
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          ref="table"
          @on-selected-rows-change="selectionChanged"
          :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
          :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      >

        <!-- Slot: Table Column -->
        <template
            slot="table-column"
            slot-scope="props"
        >

        <span>
           {{ $t(props.column.label) }}
        </span>
        </template>

        <!-- Slot: Table Row -->
        <template
            slot="table-row"
            slot-scope="props"
        >


          <!-- Column: Area -->
          <span
              v-if="props.column.field === 'area'"
              class="text-nowrap v-align-middle"
          >
          <span v-if="props.row.archived_at"> <del>{{ props.row.area }}</del></span>
          <span v-else>{{ props.row.area }}</span>
        </span>

          <!-- Column: Year -->
          <span
              v-else-if="props.column.field === 'year'"
              class="text-nowrap align-middle button-inline-spacing"
          >
           <span v-if="props.row.archived_at"> <del>{{ props.row.year }}</del></span>
          <span v-else>{{ props.row.year }}</span>

        </span>

          <!-- Column: Title -->
          <span
              v-else-if="props.column.field === 'title'"
              class="text-nowrap align-middle"
          >
          <span v-if="props.row.archived_at"> <del>{{ props.row.title }}</del></span>
          <span v-else>{{ props.row.title }}</span>

        </span>
          <!-- Column: Field -->
          <span
              v-else-if="props.column.field === 'field'"
              class="text-nowrap align-middle"
          >
<span v-if="props.row.archived_at"> <del>{{ props.row.field }}</del></span>
          <span v-else>{{ props.row.field }}</span>

        </span>

          <!-- Column: Link -->
          <span
              v-else-if="props.column.field === 'link'"
              class="text-nowrap align-middle button-inline-spacing"
          >
                 <b-button
                     v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                     variant="outline-primary"
                     class="btn-icon"
                     :href="props.row.link"
                     target="_blank"

                 >
                <feather-icon icon="Link2Icon"/>
                </b-button>

        </span>


          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row.id)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="getFulfillments(props.row.id)"

          >
      <feather-icon icon="BookIcon"/>
    </b-button>


            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteLegalRegulation(props.row)"
                :disabled="editDisabled"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

          <span v-else class="text-nowrap align-middle">


        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >

          <div class="d-flex justify-content-between flex-wrap">


            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10','15']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
            </div>
            <div>

              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>

              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <template #code>
      </template>
    </b-card-actions>
    <b-modal
        id="add-to-company"
        cancel-variant="outline-secondary"
        :ok-title="$t('Save')"
        ok-s
        :cancel-title="$t('Cancel')"
        centered
        :title="$t('Add Legal Regulations to a company')"
        @ok="addLegalRegulationToCompany"
    >
      <b-form>
        <b-form-group>
          <label for="select_company">{{ $t('Company') }}:</label>
          <b-dropdown
              :text="current_company"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-inline-spacing-2"
              id="select_company"
              block
          >
            <b-dropdown-item
                v-for="company in companies"
                :key="company.id"
                @click="selectCompany(company)"
            >
              <span class="ml-50">{{ company.name }}</span>
            </b-dropdown-item>

          </b-dropdown>
        </b-form-group>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BRow, BCol, BForm
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EquipmentService from "@/basic/equipmentService";
import LegalService from "@/basic/legalService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      columns: [
        {
          label: 'Area',
          field: 'area',
        },
        {
          label: 'Year',
          field: 'year',
        }
        , {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Field',
          field: 'field',
        },
        /*{
          label: 'Link',
          field: 'link',
        },*/
        {
          label: 'action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('Area'),
          field: 'area',
        },
        {
          label: i18n.t('Title'),
          field: 'title',
        },
        {
          label: i18n.t('Field'),
          field: 'field',
        },
        /*{
          label: i18n.t('Link'),
          field: 'link',
        },*/


      ],
      rows: [],
      selected_rows: [],
      searchTerm: '',
      companies: JSON.parse(localStorage.getItem('companies')),
      current_company: i18n.t('Company'),
    }
  },
  computed: {
    selected_rows_label() {
      let localeMessages = i18n.t('Legal Regulations');
      if (this.selected_rows.length === 1) {
        localeMessages = i18n.t('Legal Regulation');
      }
      let label = i18n.t('You selected') + ' ' + this.selected_rows.length + ' ' + localeMessages + '. ';
      return label
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true


      const legalService = new LegalService(useJwt)

      legalService.legal_regulations({}).then(response => {
        this.rows = response.data.data.legal_regulations
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false

      })

    },


    add_legalRegulation() {
      this.$router.push({name: 'legal-regulation', params: {id: 'new'}})
    },
    show_add_to_company() {
      this.$bvModal.show('add-to-company')
    },
    addLegalRegulationToCompany() {
      console.log("addLegalRegulationToCompany", this.selected_rows)
      console.log("company", this.company_id)


      const data = {
        additional_legal_regulations: this.selected_rows,
      };
      const legalService = new LegalService(useJwt)


      try {
        console.log('data', data)

        legalService.add_company_legal_regulations(this.company_id, data).then(response => {
          console.log("response", response)

          if (response.data.status === 'success') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Legal regulations added'),
                icon: 'AlertIcon',
                variant: 'success',
                text: i18n.t('Added with success'),
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Legal regulation not added'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t(response.data.message),
              },
            })
          }

        })


      } catch (error) {

      }
    },

    selectionChanged() {
      console.log("selectionChanged", this.$refs['table'].selectedRows)
      this.selected_rows = this.$refs['table'].selectedRows
    },
    selectCompany(company) {
      this.company_id = company.id
      this.current_company = company.name
    },
    edit(id) {
      this.$router.push({name: 'legal-regulation', params: {id: id}})
    },
    getFulfillments(id) {
      this.$router.push({name: 'fulfillments', params: {legal_regulation_id: id}})
    },
    deleteLegalRegulation(row) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting the legal regulation') + ": \"" + row.title + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const legalService = new LegalService(useJwt)

              legalService.delete_legal_regulation(row.id).then(response => {

                if (response.data.status === 'success') {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Legal regulation deleted'),
                      icon: 'AlertIcon',
                      variant: 'success',
                      text: i18n.t('Deleted with success'),
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: i18n.t('Legal regulation not deleted'),
                      icon: 'AlertIcon',
                      variant: 'danger',
                      text: i18n.t(response.data.message),
                    },
                  })
                }


                this.refresh();
              })
            }
          })

    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
  },
  mounted() {
    const equipmentService = new EquipmentService(useJwt)


    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });
    this.refresh();

  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>
